.side-logo{
    width: 170px;
}

.input-search{
    width: 400px;
}

.t-image{
    width: 50px;
    height: 50px;
}

.p-image{
    width: 200px;
    height: 200px;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

.bg-primal{
      background-color: $primal !important
}

.primal{
    color: $primal !important
}

.secondary{
    color: $secondary-color !important
}

.pointer {
    cursor: pointer;
}

.white{
    color: #ffff;
}

.tac{
    text-align: center;
}

.modal-size{
    width: 1200px;
    max-width: 1200px;
}

.check_offer{
    position: relative;
    top: 2px;
}

